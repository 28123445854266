

@import "~react-image-gallery/styles/css/image-gallery.css";


.root-158 {
  padding-top: 0px !important
}

.cell-191 {
  margin: auto !important;
  text-align: center !important;
}

.cellTitle-180 {
  justify-content: center !important;
}

.ms-DetailsRow{
  border-bottom: 1px solid rgb(161 159 157);
}
.l{
 color: #FAF9F8;
background-color: white;}

