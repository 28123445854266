.customerDetail-wrapper {
    grid-template-columns: 1fr 1fr;
    width: 60%;
}

.wrap-details {
    width: 70%;
}

.titleIn {
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
}

.wrap-note {
    width: 70%;

}

.wrpper-related-title {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    margin-right: 210px;
}

.ms-dialogMainOverride {
    max-width: 2000px !important;
    max-height: 900px !important;
}

.textDialog {
    max-width: 60%;
    width: 900px;
    max-height: 100%;
    height: 600px;
}

.wrap-table {
    width: 67% !important;
    margin-right: 21% !important;
}

.wrap-title {
    margin-right: 21%;
    margin-top: 2%;
}

.customer-email>div>div>div>input{
    direction: ltr;
    text-align: right;
}