.td-reporting-details{
    border: 1px solid black;
     text-align: start;
}
.title-page{
    text-align: center;
    font-weight: bold;
    text-decoration: underline 2px;
    font-size: 18px;
}
.text-start{
    text-align: start !important;
}
.contant-page{
    width:50%;
    justify-content: center;
}
.label-page{
    text-decoration: underline ;

}
.div-report-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contant-reports-details{
    width: 400px;
}
.div-width{
    width:100%
}