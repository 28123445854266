.title-receipt{
    display: flex;
   width: 100%;
   flex-wrap: wrap;
   grid-gap: 6px;
   gap: 6px;
}

.total-calc-receipt {
    line-height: 1.5;
    width: 152px;
    text-align: start;
    justify-content: start;
    display: flex;
    
}.total-calc-receipt-out {
    line-height: 1.5;
    width: 137px;
    text-align: start;
    justify-content: start;
    display: flex;
}