// html{
//     max-width:1920px
//     ;
// }
// html{
    
//     min-height: 1800px;
    
//     // width: 100vw;
//     // height: 100vh;
 
// }
// .grid-container{
//     display: grid;
//     // 
//     grid-template-areas: 
//       "header header  header  header  header"
     
//       " side main main main main "
//        "side main main main main " ;
  

  
//     height: 100vh;
//     overflow: auto;
//     margin-top: 90px;
      

    
// }
.grid-container{
    display:inherit;
    margin-top: 50px;
    grid-template-areas: 
      "header header  header  header  header" 
      " side   main   main    main    main "
       "side    main  main    main    main " 
       " side   main   main   main    main "
       "side   main    main   main    main " ;
     
    overflow: auto;;
    min-height: 100%;

   
}
@media only screen and (min-width: 6px){
    .grid-container{
        margin-top: 50px;
        overflow:auto;
        min-height: 100%;
        // min-height: 1800px;
        grid-template-columns: 1fr 4fr ;
        grid-template-areas:
        "header header  header  header  header"
      " side   main   main    main    main "
       "side    main  main    main    main " 
       " side   main   main   main    main "
       "side   main    main   main    main " ;;
      }
}
  