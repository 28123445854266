.wrpper-related-title {
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    margin-right: 210px;
}

.ms-dialogMainOverride {
    max-width: 2000px !important;
    max-height: 900px !important;
}

.textDialog {
    max-width: 100%;
    width : 900px;
    max-height: 100%;
    height : 600px;
}
.wrap-table{
    width: 90%!important;
    margin-right: 240px !important;
}
.wrap-title{
    margin-right: 240px !important;
    width: 610px;
}
.attechedF{
width: 610px;
    justify-content: space-between;
    display: inline-flex;
}
