.title-h1 {
    margin: 5px;
}

.title-h2 {
    margin: 10px;
}

.title-h3 {
    margin: 15px;
}

.input-date {
    width: 100% !important;
    height: 32px !important;
    padding: 0px 8px !important;
    font-size: 14px;
    overflow: hidden !important;
    background-color: white;
    border: 1px solid rgb(96, 94, 92);
}

.input-date:focus-visible {
    width: 100% !important;
    height: 32px !important;
    padding: 0px 8px !important;
    font-size: 14px;
    overflow: hidden !important;
    outline: inherit !important;
    outline: none;
    background-color: white !important;
    border: 2px solid rgb(0, 120, 212) !important;
}

.readOnly-date {
    background-color: #FAF9F8;
    // font-weight: bold;
    font-size: 15px;
    width: 100% !important;
    height: 32px !important;
    padding: 0px 8px !important;
    font-size: 14px;
    border: 1px solid rgb(96, 94, 92);
}

.readOnly-date:focus-visible {
    background-color: #FAF9F8;
    // font-weight: bold;
    width: 100% !important;
    height: 32px !important;
    padding: 0px 8px !important;
    font-size: 14px;
    outline: none;
    border: 2px solid rgb(0, 120, 212);
}
.disabled-discount{
    background-color: rgb(250, 249, 248) !important;
    color: rgb(161, 159, 157)!important;
    border-width: 1px!important;
}
.disabled-discount>div>div>div>input{
    background-color: #faf9f8 !important;
    color: #bebcba !important;
    border: solid 1px !important;
    height: 32px;
    margin-top: -1px;

}

.disabled-date {
    background-color: #FAF9F8;
    // font-weight: bold;
    width: 100% !important;
    height: 32px !important;
    padding: 0px 8px !important;
    font-size: 14px;
    border: 1px solid rgb(210 209 209);
    background-color: rgb(250, 249, 248);
    color: rgb(161, 159, 157);
}

.disabled-date:focus-visible {
    background-color: #FAF9F8;
    // font-weight: bold;
    width: 100% !important;
    height: 32px !important;
    padding: 0px 8px !important;
    font-size: 14px;
    outline: none;
    background-color: rgb(250, 249, 248);
    color: rgb(161, 159, 157);
    border: 1px solid rgb(0, 120, 212);
}


.require-date {
    background-color: #FAF9F8;
    border: 3px solid red;
}

.doc .react-datepicker-popper {
    position: static !important;
    transform: none !important;
}

//   .popper-container {
//     z-index: 90;
//   }

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

// .padding-input {
//     padding-right: 8px !important;
// }

// .padding-currency {
//     padding-right: 9px !important;
// }

.padding-items {
    padding: 5px 0px;
}

// .margin-between-input {
//     margin-right: 6px !important;
// }

// .marginL-between-container {
//     margin-left: 15px !important;
//     padding: 0 !important;
// }

// .marginR-between-container {
//     margin-right: 15px !important;
//     padding: 0 !important;
// }

.sortable-handler {
    touch-action: none;
}

// .search-small {
//     -webkit-transition: all 0.7s ease 0s;
//     -moz-transition: all 0.7s ease 0s;
//     -o-transition: all 0.7s ease 0s;
//     transition: all 0.7s ease 0s;
//     width: 100px !important;
//     direction: rtl
// }

// .search-small:focus-within {
//     width: 190px !important;
// }

// .title-calc-difference-convertion {
//     width: 120px;
//     padding-left: 6px;
//     text-align: end;
// }


.currency-input {
    height: 32px;
    border-width: 1px;
    background-color: white;
    border: 1px solid rgb(96 94 92);
    border-style: solid;
    outline: none;
}

.currency-input:focus-visible {
    height: 32px;
    border-width: 1px;
    background-color: white;
    border-style: solid;
    outline: none;
    border: 2px solid rgb(0, 120, 212);
}

.icon-arrow-t {
    align-items: center;
    color: gray;
    cursor: pointer;
    margin-bottom: 0%;
    margin-top: 5px;
    margin-right: 3px;
    font-size: 13px;
}

// .icon-sort-t {
//     align-items: center;
//     margin-bottom: 1px;
//     color: gray;
//     cursor: pointer;
//     margin-bottom: 0%;
//     margin-top: 5px;
//     margin-right: 3px;
//     font-size: 13px;
// }

.ms-TextField-fieldGroup:focus-visible {
    outline: none !important;
    outline-width: 0 !important;
    border-style: hidden !important;
    border: solid 1px rgb(0, 120, 212) !important;
    box-sizing: border-box;

}

.ms-TextField-field:focus {
    outline: none !important;
    outline-width: 0 !important;
    border-style: hidden !important;
    border: none !important;
    --webkit-appearance: none;
}

.ms-TextField-fieldGroup:focus {
    outline: none !important;
    border-style: hidden !important;
    border: none !important;
    --webkit-appearance: none;
}

.i-globe {
    color: white !important;
    font-size: 20px !important;
}

.ms-CommandBar-primaryCommand {
    height: 26px !important;
}

.ms-CommandBarItem-link {
    background-color: #1a1f71;
}

.ms-CommandBar {
    height: 26px !important;
    background-color: #1a1f71 !important;
    padding: 0 !important;
}