.newCollateral{
    display: inline;
    justify-content: space-between;
}
.attechedCl{
    width: 1000px ;
    justify-content: space-between;
    display: inline-flex;
}
.addFile{
    cursor: pointer;
    
}
.titleC{
    display: flex;
     justify-content: space-between;
    width: 100%;
}
.inlineBf{
    display: flex;
   float: left;
   justify-content: space-between;
 width: 100%;
}

.inlineB { 
     width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 6px;
  }
.bold{
    width:15% ;
}
.boldC{
    font-weight: bold;
    font-size: 18px;
}