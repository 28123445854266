.wrapper-id {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 0.5fr);
    grid-gap: 6px
}

.text-feild {
    padding-inline-start: 200px;
    max-width: 200px;
    min-width: 100px;
}
::ng-deep .fieldGroup-346{
width: 63px;
}
.text-feild-note {
    color: red;
    margin: 0;
}
.wrapper-collateral {
    grid-template-columns: 1fr 1fr;
    display: flex;
    grid-template-rows: auto;
    grid-area: main;
    align-items: self-end;
}

.warpper-fields-right {
    width: 80%;
    justify-self: right;
    padding-left: 6px;
}

.warpper-fields-right-block {
    width: 80%;
    justify-self: right;
    padding-left: 3%;
}

.note-field {
    width: 100%;
    margin-bottom: 2%;
}

.wrap-dialog {
    margin-right: 3%;
}

.atteched-field {
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
}

.addFile {
    cursor: pointer;
    font-size: 28px;
}

.wrap-fields {
    margin-bottom: 10%;
}

.addFileCol {
    cursor: pointer;
    margin-top: 3%;
}

.ScanInput {
    border: none !important;
}

.amount-check-out {
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 600;
}

   