//ltr
.wrap-title-account {
    margin-right: 240px !important;
    width: 620px;
    margin-top: 2%;
}

.wrap-title-accountCon {
    margin-right: 240px !important;
    width: 700px;
    margin-top: 2%;
}



.attechedA {
    width: 620px;
    justify-content: space-between;
    display: inline-flex;
}
.attechedAdd {
    justify-content: space-between;
    display: inline-flex;
}

.attechedACon {
    width: 535px;
    justify-content: space-between;
    display: inline-flex;
}
.attechedAConDetails {
    justify-content: space-between;
    display: inline-flex;
}
.attechedAConDetails {
    justify-content: space-between;
    display: inline-flex;
}

//link
.ms-Nav-linkText {
    text-overflow: clip;
    width: fit-content;
}

.ms-Nav-link {
    margin-right: 0;
    padding: 0;
}

.ms-Nav-link:active {
    margin-right: 0;
    padding: 0;
    color: rgb(0, 120, 212)
}


.ms-Icon {
    margin-left: 0;
    display: flex;
    justify-content: center;
}

.ms-Icon:active {
    margin-left: 0;
    display: flex;
    justify-content: center;
    color: rgb(0, 120, 212);
}

.is-selected>a>span>i>svg {
    color: rgb(0, 120, 212) !important;
}

.is-selected>a {
    color: rgb(0, 120, 212) !important;
}

.is-selected>a>span>i>i {
    color: rgb(0, 120, 212) !important;
}
a>span>i>i:hover{
    color: rgb(0, 120, 212) !important;
}