.total-discount {
    justify-content: end;
    display: flex;
}

.total-calc-discount {
    line-height: 1.5;
    width: 173px;
    text-align: start;
    justify-content: start;
    display: flex;
}

.total-calc-discount-out {
    line-height: 1.5;
    width: 146px;
    text-align: start;
    justify-content: start;
    display: flex;
}

.suffix-currency {
    display: flex;
    z-index: 1;
    font-weight: 100;
    align-items: center;
    background-color: #f3f2f1;
    border: solid 1px #747070;
    border-right: none;
    height: 32px;
    padding: 0px 10px;
}

.title-in {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    grid-gap: 6px;
    gap: 6px;
}

.scan-div {
    border: solid 1px grey;
    width: 2.5%;
    padding: 0px;
    margin: 0;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.chequeExpenses {
    width: 8%;
    height: 32px;
    background-color: #FAF9F8;
    border-width: 1px;
    border-style: solid;
    outline-color: rgb(0, 120, 212)
}
.chequeExpenses:focus-visible{
    width: 8%;
    height: 32px;
    background-color: #FAF9F8;
    border:2px solid rgb(0, 120, 212);
    outline:none;
}