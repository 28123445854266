// .pagination {
//   margin: 15px auto;
//   display: flex;
//   list-style: none;
//   outline: none;
// }
// .pagination > .active > a{
//   background-color: #47ccde ;
//   border-color: #47ccde ;
//   color: #fff;
// }
// .pagination > li > a{
//   border: 1px solid #47ccde ;
//   padding: 5px 10px;
//   outline: none;
//   cursor: pointer;
// }
// .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
//   background-color: #47ccde ;
//   border-color: #47ccde;
//   outline: none ;
// }
// .pagination > li > a, .pagination > li > span{
//   color: #47ccde

// }
// .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
//   border-radius: unset
// }




.issuesPagination .selected a {
  box-shadow: 0 0 2px #1b7d9e;
  background-color: #f3fcff;
}

.issuesPagination .disabled>a {
  color: #ccc;
  background-color: #f8f8f8;
  border-color: #eee;
  cursor: default;
}

.issuesPagination .disabled>a:hover {
  background-color: #f8f8f8;
}

.issuesPagination .break {
  margin: 0 8px;
}

.issuesPagination .previous {
  margin-right: 1rem;
}

.issuesPagination .next {
  margin-left: 1rem;
}




.searchWp__paginationContainer__pagination>div>button{
  min-width: 40px !important;
  padding: 0 !important;
}