.hoverRow:hover {
  background-color: rgb(243 243 243);
 
}
.hoverRow {
  background-color: rgb(255 255 255);
}
.hoverRowT {
  background-color: rgb(243 243 243);
}
.tdCenter {
  border: none;
  height: 46px;
  text-align: start;
  color: grey;
  margin: auto;
  width: "60px"
}
/* rtl */
/* .td-sum-profit {
  padding-right: 6px;
  border: none;
  height: 46px;
  text-align: end;
  color: grey;
  width: "60px"
} */

/* .td-currency-sum-profit {
  padding-right: 8px;
  border: none;
  height: 46px;
  text-align: start;
  color: grey;
  width: "60px"
} */

/* .sub-title-sum-profit {
  padding-right: 4px;
  border: none;
  color: gray;
  background-color: #e1e1e1;
  text-align: start
} */

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(172, 170, 170);
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table-spaces {
  margin-top: 4px;
}

.wrapper {
  display: block;
  justify-content: space-between;
}

@media screen and (min-width:1400px) {
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
}

.td-border {
  border-left: 1px solid rgb(225, 225, 225);
}

.th-complete-table {
  border: none;
  background-color: #e1e1e1;
  height: 10px;
  text-align: start;
  color: gray;
}

.td-account-table {
  border: none;
  height: 46px;
  text-align: start;
  color: grey;
}

.th-account-table {
  border: none;
  height: 46px;
  justify-content: space-between;
  color: grey;
  display: flex;
}
.link-document{
  cursor: pointer;
  border-bottom:solid 2px grey !important;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
}
.link-document-home-page{
  cursor: pointer;
  border-bottom:solid 2px grey !important;
  line-height: 1;
  font-weight: 400;
}


