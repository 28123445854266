.userName{
    top:70px;
    height: 120px;
    max-width:230px;
    background-color: yellow;
    font-family: 'Font Awesome 5 Pro';
}

// .sidebar{
//     max-width: 27%;
//     height: 100%;
//     box-sizing: border-box;
//     grid-area: side;
//     display: flex;
//     justify-content:space-around;
//     position: fixed;
//     background-color:white;
//     border-left: 1px solid lightgrey;
// }
.side-sub{
    color: #1A1F71;
}


// display:flex;
// justify-content:space-around;
// height: 100vh;
// width: 230px;
// background-color:white;
// // .dd{
//     margin-left: 0px !important;
// }

.items{   
    //c
    overflow: hidden;
    max-width: 210px;
    min-height:100% ;
    font-size: 11px !important;
}



.catgory{
    background: #1A1F71;
     margin-top:0px;
     min-height:100%;
     max-width: 70px;

}
.warper{
    flex-direction: column;
    
}    

.navStyle{
    background: #1A1F71;
    color: black
}
.not-premission{
    text-align: center;
    justify-content: center;
    font-size: 50px;
    margin-top: 10%;
}

// .warapperSvg>svg{
//     color:'#FFB400' !important
// }
// .linkItems{
//     color:#FFB400 !important;
//     fill:#FFB400 !important;
// }
// .linkItems>svg{
//     color:#FFB400 !important;
//     fill:#FFB400 !important;
// }
// .ms-Button-icon>i{
//     color:#FFB400 !important;
// } 
// .ms-Button-icon>svg{
//     color:#FFB400 !important;
// } 