.wrap-table{
    width: 67% !important;
    margin-right: 21% !important;
}
.wrap-title{
    margin-right: 21%;
    margin-top: 0 !important;
    width: 67% !important;
}
.atteched{
    justify-content: space-between;
    display: inline-flex;
    margin-right: 240px;
    width: 67%;
    margin-top: 2%;
}
.addFile{
    cursor: pointer;
    margin-top: 0 !important;
}
.addFileD{
    cursor: pointer;
}