.wrpper-plan-title{
    display: flex;
    justify-content: space-between;
}
.marginRight{
    margin-right: 20%;
}
.wrap-table{
    width: 78%;
    margin-right: 20%;
}

.wrapper-collateralPlan{
    width: 100%;
    grid-template-columns: 1fr 1fr;
        display: flex;
        grid-template-rows: auto;
        grid-area: main;
        align-items: self-start;
 }
 /* .warpper-fields-leftP{
     width: 40%;
     justify-self: left;
     padding-right: 7%;
 } */
 .buttonPlan{
     width: 100%;

 }
 /* .warpper-fields-rightPl{
    width: 50%;
    justify-self: right;
    padding-left: 6px;
} */

.inside{
    width: 22.5%;
    margin-bottom: 5px;

}
 .note-field{
     width: 100%;
     margin-bottom: 2%;
 }
 /* .wrap-dialogPlan{
     margin-right: 240px;
 } */
 .atteched-field{
     width: 100%;
     justify-content: space-between;
     display: inline-flex;
 }
 .addFile{
     cursor: pointer;

 }
 .wrap-fields{
     margin-bottom: 10%;
 }
 .addFileCol{
    cursor: pointer;
    margin-top: 3% ;
 }
.fields{
    display: flex;
    width: 60%;
    justify-content: space-between;
}
.field{
    /* margin: 5px; */
    display: flex;
    justify-content: center;
    width: 50%;
}
.boldF{
    /* font-family: Calibri; */
    font-family: -apple-system, BlinkMacSystemFont,
    “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
    “Fira Sans”, “Droid Sans”, “Helvetica Neue”,
    Calibri;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    align-self: center;
    margin-bottom: 0;
    /* padding-top: 5px; */
}
.boldPP{
    width: 33%;
    /* font-family: Calibri; */
    font-family: -apple-system, BlinkMacSystemFont,
    “Segoe UI”, “Roboto”, “Oxygen”, “Ubuntu”, “Cantarell”,
    “Fira Sans”, “Droid Sans”, “Helvetica Neue”,
    Calibri;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0 !important;
    /* padding-top: 5px; */
}
.titleC{
    display: flex;
     justify-content: space-between;
    width: 100%;
}
.attechedCl{
    width: 1000px ;
    justify-content: space-between;
    display: inline-flex;
}